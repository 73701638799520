var MUTE_TOKEN_LIST: any = [
  {
    chainId: 324,
    address: "0x0000000000000000000000000000000000000000",
    name: "Ethereum",
    symbol: "ETH",
    decimals: 18,
    logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
  },
  {
    chainId: 324,
    address: "0x5a7d6b2f92c77fad6ccabd7ee0624e64907eaf3e",
    name: "zkSync",
    coin: "coinZk",
    symbol: "ZK",
    decimals: 18,
    logoURI: "https://assets.coingecko.com/coins/images/38043/standard/zk.jpg?1716339607",
  },
  {
    chainId: 324,
    address: "0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91",
    name: "Wrapped ETH",
    symbol: "WETH",
    decimals: 18,
    logoURI: "https://assets.coingecko.com/coins/images/2518/small/weth.png?1628852295",
  },
  {
    chainId: 324,
    address: "0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4",
    name: "Bridged USDC",
    symbol: "USDC.e",
    decimals: 6,
    logoURI: "https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389",
  },
  {
    chainId: 324,
    address: "0x1d17CBcF0D6D143135aE902365D2E5e2A16538D4",
    name: "USD Coin",
    symbol: "USDC",
    decimals: 6,
    logoURI: "https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389",
  },
  {
    chainId: 324,
    address: "0x493257fD37EDB34451f62EDf8D2a0C418852bA4C",
    name: "Tether USD",
    symbol: "USDT",
    decimals: 6,
    logoURI: "https://assets.coingecko.com/coins/images/325/standard/Tether.png?1696501661",
  },
  {
    chainId: 324,
    address: "0xa995ad25ce5eb76972ab356168f5e1d9257e4d05",
    name: "Koi",
    coin: "coinKoi",
    symbol: "KOI",
    decimals: 18,
    logoURI: "https://preview.koi.finance/static/media/koi.b19d3d1ff21069b85c9a.png",
  },
  {
    chainId: 324,
    address: "0x0e97C7a0F8B2C9885C8ac9fC6136e829CbC21d42",
    name: "Mute",
    symbol: "MUTE",
    decimals: 18,
    logoURI: "https://assets.coingecko.com/coins/images/14331/small/MUTE.png?1617618967",
  },
  {
    chainId: 324,
    address: "0xBBeB516fb02a01611cBBE0453Fe3c580D7281011",
    name: "Wrapped BTC",
    symbol: "WBTC",
    decimals: 8,
    logoURI: "https://assets.coingecko.com/coins/images/7598/small/wrapped_bitcoin_wbtc.png?1548822744",
  },
  {
    chainId: 324,
    address: "0xc8Ec5B0627C794de0e4ea5d97AD9A556B361d243",
    name: "Whisper",
    symbol: "WISP",
    decimals: 18,
    logoURI: "https://assets.coingecko.com/coins/images/29650/small/wisp_200.png?1681725709",
  },
  {
    chainId: 324,
    address: "0xbFB4b5616044Eded03e5b1AD75141f0D9Cb1499b",
    name: "zkDoge",
    symbol: "ZKDOGE",
    decimals: 18,
    logoURI: "https://assets.coingecko.com/coins/images/29619/standard/zkdoge.png?1696528555",
  },
  {
    chainId: 324,
    address: "0x45559297BdEDf453e172833AC7086f7D03f6690B",
    name: "ZK INU",
    symbol: "$ZKINU",
    decimals: 18,
    logoURI: "https://github.com/muteio/token-directory/raw/main/icons/ZKINU.png",
  },
  {
    chainId: 324,
    address: "0xa51bc2433a33c448dd40f7074bcab751a1922706",
    name: "ZKInu",
    symbol: "ZINU",
    decimals: 18,
    logoURI: "https://github.com/muteio/token-directory/raw/main/icons/zinu.png",
  },
  {
    chainId: 324,
    address: "0x7C9becB53A7702244Ca260Fd01B4748756CE7BB3",
    name: "ZKRISE",
    symbol: "ZKRISE",
    decimals: 18,
    logoURI: "https://github.com/muteio/token-directory/raw/main/icons/zkrise.png",
  },
  {
    chainId: 324,
    address: "0x959aB3394246669914BdDEAeB50f8Ac85648615e",
    name: "zkLaunchpad",
    symbol: "ZKPAD",
    decimals: 18,
    logoURI: "https://github.com/muteio/token-directory/raw/main/icons/zklaunchpad.png",
  },
  {
    chainId: 324,
    address: "0x8d266fA745b7Cf3856AF0c778828473B8d33a149",
    name: "zkFloki",
    symbol: "ZFLOKI",
    decimals: 18,
    logoURI: "https://github.com/muteio/token-directory/raw/main/icons/ZFLOKI.png",
  },
  {
    chainId: 324,
    address: "0xF42d97Dbff4AdC5fe32966338C306859A5766511",
    name: "ZoKe Inu",
    symbol: "ZKINU",
    decimals: 9,
    logoURI: "https://github.com/muteio/token-directory/raw/main/icons/ZoKeInu.png",
  },
  {
    chainId: 324,
    address: "0x5e38CB3e6c0faaFAa5C32C482864fCef5A0660Ad",
    name: "Zksync Shib",
    symbol: "ZKSHIB",
    decimals: 18,
    logoURI: "https://github.com/muteio/token-directory/raw/main/icons/zkshib.png",
  },
  {
    chainId: 324,
    address: "0xC8Ac6191CDc9c7bF846AD6b52aaAA7a0757eE305",
    name: "Metavault Trade",
    symbol: "MVX",
    decimals: 18,
    logoURI: "https://github.com/muteio/token-directory/raw/main/icons/mvx.png",
  },
  {
    chainId: 324,
    address: "0xDea6d5161978d36b5C0FA6a491faA754f4BC809C",
    name: "Idexo Token",
    symbol: "IDO",
    decimals: 18,
    logoURI: "https://github.com/muteio/token-directory/raw/main/icons/idexo.png",
  },
  {
    chainId: 324,
    address: "0x503234F203fC7Eb888EEC8513210612a43Cf6115",
    name: "Liquity USD",
    symbol: "LUSD",
    decimals: 18,
    logoURI: "https://github.com/muteio/token-directory/raw/main/icons/lusd.png",
  },
  {
    chainId: 324,
    address: "0x89F69aD54e5D05A7A7A1314ecBc7d56D965D3020",
    name: "Wojak Coin Zk",
    symbol: "$WOJAK",
    decimals: 18,
    logoURI: "https://github.com/muteio/token-directory/raw/main/icons/Wojak.png",
  },
  {
    chainId: 324,
    address: "0x61b41e0244133b9c9c47a57e51a5ef70be2c5dd4",
    name: "Bolt",
    symbol: "BOLT",
    decimals: 18,
    logoURI: "https://assets.coingecko.com/coins/images/30082/standard/cropped-Group-75-300x258.png?1696529006",
  },
  {
    chainId: 324,
    address: "0xA4C00e85ceBc898e885F5055171dc424dbA8bF45",
    name: "Panda",
    symbol: "PANDA",
    decimals: 18,
    logoURI: "https://github.com/muteio/token-directory/raw/main/icons/panda.png",
  },
  {
    chainId: 324,
    address: "0x8E817eEe4A987EAd05d03cCACb354F67b0DBd63d",
    name: "zkINA",
    symbol: "ZKINA",
    decimals: 18,
    logoURI: "https://github.com/muteio/token-directory/raw/main/icons/zkina.png",
  },
  {
    chainId: 324,
    address: "0x8E86e46278518EFc1C5CEd245cBA2C7e3ef11557",
    name: "USD+",
    symbol: "USD+",
    decimals: 6,
    logoURI: "https://assets.coingecko.com/coins/images/25757/small/USD__logo.png?1653519267",
  },
  {
    chainId: 324,
    address: "0xc76997c863bac0f6e4b6d3c2404b06013a0b29e2",
    name: "HeartX Utility Token",
    symbol: "HNX",
    decimals: 18,
    logoURI: "https://assets.coingecko.com/coins/images/30531/small/HNX_icon.png?1684890258",
  },
  {
    chainId: 324,
    address: "0xe593853b4d603d5b8f21036Bb4AD0D1880097a6e",
    name: "Fulcrom",
    symbol: "FUL",
    decimals: 18,
    logoURI: "https://assets.coingecko.com/coins/images/29684/small/FUL_Token_%281%29.png?1680600867",
  },
  {
    chainId: 324,
    address: "0x4B9eb6c0b6ea15176BBF62841C6B2A8a398cb656",
    name: "Dai",
    symbol: "DAI",
    decimals: 18,
    logoURI: "https://assets.coingecko.com/coins/images/9956/standard/Badge_Dai.png?1696509996",
  },
  {
    chainId: 324,
    address: "0x244C238325fC1Bdf6EdeD726EE1b47d55895D944",
    name: "Zorro",
    symbol: "ZORRO",
    decimals: 18,
    logoURI: "https://www.zorro.gg/static/media/logo192.94114007fca31c37dc85.png",
  },
  {
    chainId: 324,
    address: "0xed4040fd47629e7c8fbb7da76bb50b3e7695f0f2",
    name: "Holdstation",
    symbol: "HOLD",
    decimals: 18,
    logoURI: "https://assets.coingecko.com/coins/images/32726/standard/HOLD.png?1699018663",
  },
  {
    chainId: 324,
    address: "0x703b52F2b28fEbcB60E1372858AF5b18849FE867",
    name: "Wrapped Staked ETH",
    symbol: "wstETH",
    decimals: 18,
    logoURI: "https://assets.coingecko.com/coins/images/18834/standard/wstETH.png?1696518295",
  },
  {
    chainId: 324,
    address: "0x8ba0aae056de87aefcbc973623d9d43470eae248",
    name: "Pirex Ether",
    symbol: "pxETH",
    decimals: 18,
    logoURI: "https://assets.coingecko.com/coins/images/33608/standard/pxETH-icon-transbg_72dpi.png?1702512024",
  },
  {
    chainId: 324,
    address: "0xBD4372e44c5eE654dd838304006E1f0f69983154",
    name: "Nodle Token",
    symbol: "NODL",
    decimals: 18,
    logoURI: "https://assets.coingecko.com/coins/images/27019/standard/nodle.png?1696526071",
  },
  {
    chainId: 324,
    address: "0xb4C1544cb4163f4C2ECa1aE9Ce999F63892d912A",
    name: "Frax",
    symbol: "FRAX",
    decimals: 18,
    logoURI: "https://assets.coingecko.com/coins/images/13422/standard/FRAX_icon.png?1696513182",
  },
  {
    chainId: 324,
    address: "0x19bc97d3c223fc8a0ff0541d260f4f438d5faf99",
    name: "Frax Share",
    symbol: "FXS",
    decimals: 18,
    logoURI: "https://assets.coingecko.com/coins/images/13423/standard/Frax_Shares_icon.png?1696513183",
  },
  {
    chainId: 324,
    address: "0x22f91e9436c220af83fb0ce27a08918dd1d27d32",
    name: "Staked Frax Ether",
    symbol: "sfrxETH",
    decimals: 18,
    logoURI: "https://assets.coingecko.com/coins/images/28285/standard/sfrxETH_icon.png?1696527285",
  },
  {
    chainId: 324,
    address: "0xd4169E045bcF9a86cC00101225d9ED61D2F51af2",
    name: "Wrapped rsETH",
    symbol: "wrsETH",
    decimals: 18,
    logoURI: "https://assets.coingecko.com/coins/images/37919/standard/rseth.png?1715936438",
  },
  {
    chainId: 324,
    address: "0x27d0A2b5316b98088294378692F4EAbfB3222e36",
    name: "ZeroLend",
    symbol: "ZERO",
    decimals: 18,
    logoURI: "https://assets.coingecko.com/coins/images/37375/standard/image.png?1714884543",
  },
  {
    chainId: 324,
    address: "0xD78ABD81a3D57712a3af080dc4185b698Fe9ac5A",
    name: "Venus",
    symbol: "XVS",
    decimals: 18,
    logoURI: "https://assets.coingecko.com/coins/images/12677/standard/download.jpg?1696512482",
  },
  {
    chainId: 324,
    address: "0xCe59e272946458dA50C36Ca1E731ED6C5752669F",
    name: "AglaMerkl",
    symbol: "aglaMerkl",
    decimals: 18,
    logoURI: "https://raw.githubusercontent.com/AngleProtocol/angle-token-list/main/src/assets/tokens/angle-icon-colorback-black500.png",
  },
  {
    chainId: 324,
    address: "0x83387406a0b1C2Fcc92901Ca9BB4395Ff4b36B63",
    name: "eBeggar",
    symbol: "BEG",
    decimals: 18,
    logoURI: "https://ebeggar.club/assets/mascot-BcdEp80C.png",
  },
  {
    chainId: 324,
    address: "0x7715c206a14ac93cb1a6c0316a6e5f8ad7c9dc31",
    name: "Mountain Protocol USD",
    symbol: "USDM",
    decimals: 18,
    logoURI: "https://assets.coingecko.com/coins/images/31719/standard/usdm.png?1696530540",
  },
  {
    chainId: 324,
    address: "0xa900cbe7739c96d2b153a273953620a701d5442b",
    name: "Wrapped Mountain Protocol USD",
    symbol: "wUSDM",
    decimals: 18,
    logoURI: "https://assets.coingecko.com/coins/images/33785/standard/wUSDM_PNG_240px.png?1702981552",
  },
  {
    "chainId": 324,
    "address": "0x1f7d02eB231E1EA3dB122e11a5F68273461dbDf3",
    "name": "Volcano",
    "symbol": "VOLCANO",
    "decimals": 18,
    "logoURI": "https://www.geckoterminal.com/_next/image?url=https%3A%2F%2Fassets.geckoterminal.com%2F0sfrjznimcyr16wsgctu6p6plkw8&w=64&q=75"
  },
  {
    "chainId": 324,
    "address": "0x0250B200422eA4EDc34DbeCc0F15aF182853Bf7B",
    "name": "@Never",
    "symbol": "Never",
    "decimals": 18,
    "logoURI": "https://ipfs-chainsafe.dev/ipfs/QmNvHN21P4TCALk3VYeyFAvduUQgiHmKyEiLBnyk9KYbgq"
  },
  {
    "chainId": 324,
    "address": "0xDa562340A395ba2F5D72a5EE1B1b60c578768578",
    "name": "GORILLA ARMY",
    "symbol": "GORILLA",
    "decimals": 18,
    "logoURI": "https://ipfs-chainsafe.dev/ipfs/QmVLzbtauzrmLfwd8Zd8ypgjqXJtXKzpuyvDk7pgmoFMf1"
  },
  {
    "chainId": 324,
    "address": "0x82bED26789f4C3Bb6A0d1053D9Cc8d6d0C6b26D2",
    "name": "Elastic Man",
    "symbol": "MAN",
    "decimals": 18,
    "logoURI": "https://ipfs-chainsafe.dev/ipfs/QmdowaQY1Wti131vSwhbdPofrXM2afynmf7rEEC2V8v9Bx"
  },
  {
    "chainId": 324,
    "address": "0x97734f72Ca1c0a1dC5F08a5b09204CB2Bd887A2F",
    "name": "GATES",
    "symbol": "$GTS",
    "decimals": 18,
    "logoURI": "https://ipfs-chainsafe.dev/ipfs/QmS31Mxd1K3ZvWnGukJWbooraYDyDKRxbvoxRW6sAcym8R"
  },
  {
    "chainId": 324,
    "address": "0x5d0d7bca050e2e98fd4a5e8d3ba823b49f39868d",
    "name": "Zyfi",
    "symbol": "ZFI",
    "decimals": 18,
    "logoURI": "https://assets.coingecko.com/coins/images/39770/standard/Zyfi_Favicon_HQ_1.png?1732011570"
  },
  {
    "chainId": 324,
    "address": "0xb72207E1FB50f341415999732A20B6D25d8127aa",
    "name": "zkETH",
    "symbol": "zkETH",
    "decimals": 18,
    "logoURI": "https://zksync.dinero.xyz/images/zkETH.svg"
  },
  {
    "chainId": 324,
    "address": "0x3E9C747db47602210EA7513c9D00abf356b53880",
    "name": "aiws",
    "symbol": "aiws",
    "decimals": 18,
    "logoURI": "https://dd.dexscreener.com/ds-data/tokens/zksync/0x3e9c747db47602210ea7513c9d00abf356b53880.png?size=lg&key=a7068a"
  },
];


export default MUTE_TOKEN_LIST;
