import React from "react";
import {EmptyWidgetText} from "../../common/widgets/Widget";
import UnknownToken from "../../assets/images/icons/unknown_token.png";

import {Dropdown} from "./Dropdown";
import {useWalletHook} from "../../web3/walletHook";

import BigNumber from "bignumber.js";
import {Card, Flex, Scroll, useLayoutMq} from "../../ui/components";
import { DropdownV3 } from "./DropdownV3";
import { FaArrowUp } from "react-icons/fa";
import { FaArrowDown } from "react-icons/fa";

enum Sort {
  TVL,
  APY,
}

export const AllPoolsWidget = ({manageLiquidity, ownedPairs, isLoading}) => {
  const {getTokenFromContract} = useWalletHook();

  const layoutBreak = useLayoutMq();
  
  const [sorted, setSorted] = React.useState(Sort.TVL)
  const [_ascending, setAcending] = React.useState(true)

  const sortByAPY = () => {
    if(sorted == Sort.APY)
      setAcending(!_ascending)
    else
      setAcending(true)
    setSorted(Sort.APY)
  }

  const sortByTVL = () => {
    if(sorted == Sort.TVL)
      setAcending(!_ascending)
    else
      setAcending(true)

    setSorted(Sort.TVL)
  }

  const sortedPairs = React.useMemo(() => {
    var vals: any = Object.values(ownedPairs)
      .sort((a: any, b: any) => {
        if(sorted == Sort.TVL){
          var _resA = a.totalValueLockedUSD ? a.totalValueLockedUSD : (a.reserveUSD ? a.reserveUSD : 0)
          var _resB = b.totalValueLockedUSD ? b.totalValueLockedUSD : (b.reserveUSD ? b.reserveUSD : 0)
          return Number(_resB) - Number(_resA)
        } else {
          var _apyA = typeof a.ampAPY == 'string' ? new BigNumber(a.apy).plus(a.ampAPY).toFixed(2) : (a.apy ? a.apy : 0)
          var _apyB = typeof b.ampAPY == 'string' ? new BigNumber(b.apy).plus(b.ampAPY).toFixed(2) : (b.apy ? b.apy : 0)
          
          return Number(_apyB) - Number(_apyA)
        }
      });

      if(_ascending == false){
        return vals.reverse()
      }

      return vals

  }, [ownedPairs, sorted, _ascending]);

  const getWidget = () => {
    if (isLoading) {
      return [...new Array(6)].map(() => <Card size="l" loading height={90} />);
    }

    return (
      <>
        {sortedPairs.map((item, index) => {          
          const assetAInfo = getTokenFromContract(item.token0.id);
          const assetBInfo = getTokenFromContract(item.token1.id);
          //v3 pool
          if(item.feeTier) return (
            <DropdownV3
              key={index}
              onManageClick={() => manageLiquidity(item.token0.id, item.token1.id, 0, item.feeTier)}
              icon0={assetAInfo ? assetAInfo.logo : UnknownToken}
              icon1={assetBInfo ? assetBInfo.logo : UnknownToken}
              token0={assetAInfo && assetAInfo.symbol ? assetAInfo.symbol : item.token0.symbol}
              token1={assetBInfo && assetBInfo.symbol ? assetBInfo.symbol : item.token1.symbol}
              reserveA={item.totalValueLockedToken0}
              reserveB={item.totalValueLockedToken1}
              fee={new BigNumber(item.feeTier).div(10000).toFixed(2)}
              apy={typeof item.ampAPY == 'string' ? item.apy + "-" + new BigNumber(item.apy).plus(item.ampAPY).toFixed(2) : item.apy}
              tvl={item.totalValueLockedUSD}
              rewardTokens={item.rewardTokens ? item.rewardTokens : []}
              dark={true}
            />
          );
          else return (
            <Dropdown
              key={index}
              onManageClick={() => manageLiquidity(item.token0.id, item.token1.id, item.stable ? 1 : 2)}
              icon0={assetAInfo ? assetAInfo.logo : UnknownToken}
              icon1={assetBInfo ? assetBInfo.logo : UnknownToken}
              token0={assetAInfo && assetAInfo.symbol ? assetAInfo.symbol : item.token0.symbol}
              token1={assetBInfo && assetBInfo.symbol ? assetBInfo.symbol : item.token1.symbol}
              stable={item.stable}
              fee={new BigNumber(item.pairFee).div(100).toFixed(2)}
              reserveA={item.reserve0}
              reserveB={item.reserve1}
              apy={typeof item.ampAPY == 'string' ? item.apy + "-" + new BigNumber(item.apy).plus(item.ampAPY).toFixed(2) : item.apy}
              rewardTokens={item.rewardTokens ? item.rewardTokens : []}
              tvl={item.reserveUSD}
              dark={true}
            />
          );
        })}
        {sortedPairs.length == 0 ? <EmptyWidgetText style={{textAlign: "center"}}></EmptyWidgetText> : <></>}
      </>
    );
  };

  return (
    <Flex column gap={2}>
    <Card size="l" height={50}>
      <Flex gap={2} justify="space-between" noBasis={!layoutBreak} expandVertical wrap={layoutBreak}>
        <Flex gap={4} grow={layoutBreak ? 2 : 16} order={0}>
        Pool
        </Flex>

        <Flex grow={layoutBreak ? 1 : 10} order={layoutBreak && 1}>
          Reserves
        </Flex>

        <Flex gap={3} grow={layoutBreak ? 2 : 10} order={layoutBreak && 2} style={{cursor: "pointer"}} onClick={sortByTVL}>
          TVL {sorted == Sort.TVL && _ascending && <FaArrowUp/>} {sorted == Sort.TVL && !_ascending && <FaArrowDown/>}
        </Flex>

        <Flex order={layoutBreak && 2} gap={2} grow={layoutBreak ? 1 : 10} style={{cursor: "pointer"}} onClick={sortByAPY}>
          APY {sorted == Sort.APY && _ascending && <FaArrowUp/>} {sorted == Sort.APY && !_ascending && <FaArrowDown/>}
        </Flex>


        <Flex justify="flex-end" order={layoutBreak && 10} grow={layoutBreak ? 1 : 10}>

        </Flex>
      </Flex>
    </Card>
    <Scroll padding="0 0 2" parentPadding="3 3" responsive height={90 * 6 + 12 * 5}>
      <Flex column gap={2}>
        {getWidget()}
      </Flex>
      </Scroll>
    </Flex>
  );
};
