import React from "react";
import {useHistory} from "react-router-dom";

import {PairsListContent} from "./PairsList/PairsListContent";
import {ExchangeWidget} from "./ExchangeWidget/ExchangeWidget";
import UnknownToken from "../../assets/images/icons/unknown_token.png";
import {useAccount} from "wagmi";

import BigNumber from "bignumber.js";

import {WalletState} from "../../state";
import {formatNumber} from "../../utils/formatNumber";
import {useWalletHook} from "../../web3/walletHook";
import {useWalletSelector} from "../../state/hooks";
import {Box, Button, Card, Chart, LavaChip} from "../../ui/components";
import {Pair} from "../../web3/types";

export const SwapScreen = () => {
  const {isConnected} = useAccount();
  const history = useHistory();

  const {isETH, isStable, getETH, isUSDC, getTokenPair, getPairTradeData, getPairHourData} = useWalletHook();

  const [checked, setChecked] = React.useState(false);
  const [showTrending, setShowTrending] = React.useState(false);

  const [chartLoading, setChartLoading] = React.useState(false);
  const [pairsLoading, setPairsLoading] = React.useState(false);

  const [pairPrice, setPairPrice] = React.useState("0.00");

  const [tokenA, setTokenA] = React.useState<any>(null);
  const [tokenB, setTokenB] = React.useState<any>(null);
  const [tokenPair, setTokenPair] = React.useState<any>(null);
  const trendingPairs = useWalletSelector((state: WalletState) => state.trendingPairs);
  const allPairs = useWalletSelector((state: WalletState) => state.allPairs);

  const ethPrice = useWalletSelector((state: WalletState) => state.ethPrice);
  const tokens = useWalletSelector((state: WalletState) => state.tokens);

  const [data, setData] = React.useState<any>([]);
  const [pairData, setPairData] = React.useState<Pair[]>([]);
  const externalSetTokens = React.useRef<any>(null);

  const [advancedChart, setAdvancedChart] = React.useState(false);

  const handleChange = (checked) => {
    setChecked(checked);
  };

  React.useEffect(() => {
    setPairsLoading(true);
    if (trendingPairs.length > 0) {
      var res = trendingPairs;
      var temp: Pair[] = [];

      let mute_added = false;
      let included_pairs = {};
      for (let i = 0; i < res.length; i++) {
        if (res[i].token0.symbol == "KOI" || res[i].token1.symbol == "KOI") mute_added = true;

        let token0 = tokens[res[i].token0.id.toLowerCase()];
        let token1 = tokens[res[i].token1.id.toLowerCase()];

        if (token0 == null) {
          token0 = {
            symbol: res[i].token0.symbol,
            id: res[i].token0.id,
            address: res[i].token0.id,
            logo: UnknownToken,
            decimals: res[i].token0.decimals,
          };
        }
        if (token1 == null) {
          token1 = {
            symbol: res[i].token1.symbol,
            id: res[i].token1.id,
            address: res[i].token1.id,
            logo: UnknownToken,
            decimals: res[i].token1.decimals,
          };
        }

        var price = new BigNumber(0);

        if (isETH(token1.address)) {
          token1 = getETH();
        }
        if (isETH(token0.address)) {
          token0 = getETH();
        }

        // paired against a stable, get derrived price
        if (isStable(token0.address)) {
          price = new BigNumber(res[i].token1.derivedETH).times(ethPrice);
        } else if (isStable(token1.address)) {
          price = new BigNumber(res[i].token0.derivedETH).times(ethPrice);
        } else {
          if (isETH(token0.address)) {
            token0 = getETH();
            price = new BigNumber(res[i].token1.derivedETH).times(ethPrice);
          } else if (isETH(token1.address)) {
            token1 = getETH();
            price = new BigNumber(res[i].token0.derivedETH).times(ethPrice);
          } else {
            price = new BigNumber(res[i].token0.derivedETH).times(ethPrice);
          }
        }

        let skip = false;
        for (let k in temp) {
          if (
            temp[k].token1.address?.toLowerCase() == token0.address.toLowerCase() &&
            temp[k].token2.address?.toLowerCase() == token1.address.toLowerCase()
          )
            skip = true;
          if (
            temp[k].token1.address?.toLowerCase() == token1.address.toLowerCase() &&
            temp[k].token2.address?.toLowerCase() == token0.address.toLowerCase()
          )
            skip = true;
        }


        if(included_pairs[res[i].token0.name.toLowerCase() + res[i].token1.id.toLowerCase()])
          skip = true
        if(included_pairs[res[i].token1.id.toLowerCase() + res[i].token0.id.toLowerCase()])
          skip = true

        //if (skip) continue;


        included_pairs[res[i].token0.id.toLowerCase() + res[i].token1.id.toLowerCase()] = true;
        included_pairs[res[i].token1.id.toLowerCase() + res[i].token0.id.toLowerCase()] = true;

        let apy = "0.00";

        for (let j in allPairs) {
          if (allPairs[j].id.toLowerCase() == res[i].pairAddress.toLowerCase())
            apy = allPairs[j].ampAPY
              ? new BigNumber(allPairs[j].apy).plus(allPairs[j].ampAPY).toFixed(2)
              : allPairs[j].apy;
        }

        temp.push({
          token1: token0,
          token2: token1,
          price: "$" + formatNumber(price, 2),
          apy: apy,
          dailyVolumeUSD: res[i].dailyVolumeUSD
        });

        if (i > (mute_added == true ? Object.keys(included_pairs).length : Object.keys(included_pairs).length - 1))
          break;
      }

      var _inital: Pair[] = []

      var _sorted = temp.sort((a, b) => (Number(a.dailyVolumeUSD) > Number(b.dailyVolumeUSD) ? -1 : 1)).reduce((unique, o) => {
        if(!unique.some(obj => (obj.token1.address.toLowerCase() === o.token1.address.toLowerCase() && obj.token2.address.toLowerCase() === o.token2.address.toLowerCase()) || (obj.token1.address.toLowerCase() === o.token2.address.toLowerCase() && obj.token2.address.toLowerCase() === o.token1.address.toLowerCase()))) {
          unique.push(o);
        }
        return unique;
      }, _inital);

      setPairData(_sorted.sort((a, b) => (Number(a.apy) > Number(b.apy) ? -1 : 1)));
      setPairsLoading(false);
    }

    return () => {};
  }, [isConnected, trendingPairs, tokens, ethPrice, allPairs]);

  const setToTrade = (_tokenA, _tokenB) => {
    callBackSelect(_tokenA, _tokenB);
    if (externalSetTokens.current) externalSetTokens.current(_tokenA, _tokenB);
  };

  const callBackSelect = async (_tokenA, _tokenB) => {
    setTokenA(_tokenA);
    setTokenB(_tokenB);
    setChartLoading(true);

    try {
      var pair = await getTokenPair(_tokenA.address, _tokenB.address, false);
      setTokenPair(pair);

      var quote = "base";
      if (isStable(_tokenA.address)) quote = "quote";

      var _data = await getPairTradeData(pair, quote);
      setPairPrice(new BigNumber(_data[0]).toFixed(10));
      setData(_data.reverse());
    } catch (e) {
      setData([]);
    }

    setChartLoading(false);
  };

  const actionButtonClick = () => {
    window.open("https://portal.zksync.io/bridge/", "_blank")
    // sentGaEvent('click_discord_swap_banner')
    history.push("/farm");
  };

  return (
    <>
      <Box vertical>
        <Box>
          <Box column>
            <ExchangeWidget
              callBackSelect={callBackSelect}
              externalSetTokens={externalSetTokens}
              setAdvancedChart={setAdvancedChart}
              advancedChart={advancedChart}
              showTrending={showTrending}
              setShowTrending={setShowTrending}
            />
            {/*<LavaChip
              palette="purple"
              heading={
                <>
                  ZKsync Era
                  <br />
                  Deposit tokens to the official bridge
                </>
              }
              micro
              performance={0}
            >
              <Button size="xs" transparent onClick={() => actionButtonClick()}>
                Deposit
              </Button>
            </LavaChip>*/}
          </Box>
          <Box relative>
            <Box.Fit column>
              <PairsListContent
                assetA={tokenA}
                assetB={tokenB}
                pairs={pairData}
                setToTrade={setToTrade}
                pairsLoading={pairsLoading}
              />
              {tokenPair && !advancedChart && (
                <Chart palette="purple" assetA={tokenA} assetB={tokenB} value={"$" + +pairPrice} data={data as any} />
              )}
            </Box.Fit>
          </Box>
        </Box>
        {advancedChart && (
          <Card empty height={400} border="xl">
            <iframe
              height="100%"
              width="100%"
              id="geckoterminal-embed"
              title="GeckoTerminal Embed"
              src={`https://www.geckoterminal.com/zksync/pools/${tokenPair}?embed=1&info=0&swaps=0`}
              allow="clipboard-write"
              allowFullScreen
            ></iframe>
          </Card>
        )}
      </Box>
    </>
  );
};
